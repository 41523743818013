@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.layoutWrapperMain {
  min-height: calc(100vh - var(--topbarHeight));

  @media (--viewportMedium) {
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }
}

.root {
  @apply --marketplaceModalRootStyles;
  align-items: center;
  flex-direction: column;
  padding: 90px 24px 60px;

  @media (--viewportMedium) {
    padding: 60px 0;
  }
}

.topbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.goHomeButton {
  @apply --marketplaceButtonStylesSecondaryWhite;
  background-image: url('data:image/svg+xml;utf8,<svg width="8" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M8 1.15 6.797 0 0 6.5 6.797 13 8 11.85 2.406 6.5z" fill="%23FFF1ED" fill-rule="nonzero"/></svg>');
  background-position: 20px center;
  background-size: 8px 14px;
  border-color: var(--failColorLight);
  border-width: 1px;
  color: var(--failColorLight);
  font-size: 16px;
  margin: 24px auto;
  max-width: 284px;
  min-height: 36px;
  padding: 4px 18px 0 0;
  text-align: right;

  &:hover,
  &:focus {
    background-color: var(--failColorLight);
    background-image: url('data:image/svg+xml;utf8,<svg width="8" height="13" xmlns="http://www.w3.org/2000/svg"><path d="M8 1.15 6.797 0 0 6.5 6.797 13 8 11.85 2.406 6.5z" fill="%232f4f38" fill-rule="nonzero"/></svg>');
    border-color: var(--failColorLight);
    color: var(--marketplaceColor);
  }

  @media (--viewportMedium) {
    margin: 24px 22px 0 auto;
  }
}

.logo {
  width: 164px;
}

.content {
  @apply --marketplaceModalBaseStyles;
  border: 0;
  border-radius: 30px;
  flex: 1;
  margin-top: 30px;
  overflow: hidden;
  padding: 0;
  width: 100%;

  @media (--viewportMedium) {
    margin-top: 54px;
    width: 480px;
  }

  &.confirmContent {
    background-color: var(--marketplaceColorLightest);
    padding: 12px 24px 24px;
  }
}

.emailVerificationContent {
  @apply --marketplaceModalGreen;

  & .modalIcon {
    margin: 5px auto 20px;
  }

  & .modalTitle {
    @apply --marketplaceModalGreenTitle;
  }

  & .modalMessage {
    @apply --marketplaceModalGreenMessage;
  }

  & .email {
    @apply --marketplaceModalGreenEmail;
  }

  & .bottomWrapper {
    margin-top: 15px;
    text-align: center;
  }

  & .modalHelperText {
    line-height: 24px;
  }

  & .modalHelperLink {
    color: var(--marketplaceColorLight);
  }
}

/* ================ Tabs & Form ================ */

.tabs {
  justify-content: space-between;
  margin: 12px 24px 0;

  & a {
    border-bottom: 3px solid transparent;
  }

  & .tabActive {
    border-color: var(--marketplaceColorLight);
  }
}

.tab {
  @apply --marketplaceModalTitleStyles;
  font-size: 14px;
  letter-spacing: 0.05em;
  line-height: 40px;
  margin-top: 0;
  margin-bottom: 0;

  @media (--viewportMedium) {
    font-size: 16px;
    margin-top: 0;
  }
}

.tabActive {
  color: var(--marketplaceColorLight);
  border-bottom: 3px solid var(--marketplaceColorLight);

  &:hover {
    color: var(--marketplaceColorLight);
  }
}

.form {
  margin-top: 24px;

  @media (--viewportMedium) {
    margin-top: 30px;
    padding-top: 2px;
  }
}

.loginForm {
  background-color: var(--marketplaceColorLightest);
  flex: 1;
  margin: 0;
  padding: 18px 24px 16px;
}

.signupForm {
  background-color: var(--marketplaceColorLightest);
  margin: 0;
  padding: 18px 24px 16px;
}

.bottomWrapper {
  @apply --marketplaceModalBottomWrapper;

  /* Align the helper links to the left since there isn't a Primary Button anymore */
  text-align: left;
}

/* Terms of Service modal*/
.termsModalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  padding: 0 36px;
}

.termsModalLogo {
  flex: 0 0 105px;
  width: 105px;
}

.termsWrapper {
  background-color: var(--marketplaceColorLightest);
  width: 100%;
  padding: 40px 30px;

  @media (--viewportMedium) {
    padding: 54px 40px;
  }
}

.termsHeading {
  @apply --marketplaceH3FontStyles;
  color: var(--marketplaceColor);
  font-weight: var(--fontWeightMedium);
  letter-spacing: normal;
  margin: 0;
  padding-right: 45px;

  @media (--viewportMedium) {
    margin: 0;
  }
}

/* ================ Hide Top bar in screens smaller than 768px  ================ */

.hideOnMobile {
  display: none;

  @media (--viewportMedium) {
    display: block;
  }
}

/* ================ Close icon ================ */

.verifyClose {
  @apply --marketplaceModalCloseStyles;

  /* This is not a real modal, z-index should not be modal's z-index */
  z-index: 1;
}

.closeText {
  @apply --marketplaceModalCloseText;
}

.closeIcon {
  @apply --marketplaceModalCloseIcon;
}

/* ================ Typography ================ */

/* Title of the modal */
.modalTitle {
  @apply --marketplaceModalTitleStyles;
}

/* Paragraph for the Modal */
.modalMessage {
  @apply --marketplaceModalParagraphStyles;
}

/* Make the email pop */
.email {
  font-weight: var(--fontWeightHighlightEmail);
}

/* Helper links */
.modalHelperLink {
  @apply --marketplaceLinkStyles;
  @apply --marketplaceModalHelperLink;
}

/* Helper texts for the links, not the actual links */
.modalHelperText {
  @apply --marketplaceModalHelperText;
}

.modalIcon {
  @apply --marketplaceModalIconStyles;

  /* Align the icon and rest of the content in the modal. Sign & Login doesn't use an icon, maybe we should add one? */
  margin-top: 47px;

  @media (--viewportMedium) {
    margin-top: 0;
  }
}

/* ================ Errors ================ */

.error {
  @apply --marketplaceModalErrorStyles;
}

/* ================ Social logins & SSO ================ */

.signupWithIdpTitle {
  @apply --marketplaceModalTitleStyles;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 16px;
  color: var(--matterColorDark);

  @media (--viewportMedium) {
    margin-top: 6px;
  }
}

.confirmInfoText {
  @apply --marketplaceH4FontStyles;
}

.buttonIcon {
  position: absolute;
  left: 0;
  margin-left: 20px;
}

.socialButtonWrapper {
  margin-bottom: 14px;
  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.idpButtons {
  background-color: var(--marketplaceColorLightest);
  padding: 0 24px 12px;
}

.socialButtonsOr {
  width: 100%;
  height: 32px;
  margin: 28px 0 20px 0;
  text-align: center;
  position: relative;

  &:after {
    content: '';
    border-bottom: solid 1px #d2d2d2;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    z-index: 1;
  }
}

@media (--viewportMedium) {
  .socialButtonsOr {
    height: 34px;
    margin: 15px 0;
  }
}

.socialButtonsOrText {
  background-color: var(--marketplaceColorLightest);
  width: auto;
  display: inline-block;
  z-index: 3;
  padding: 0 20px 0 20px;
  position: relative;
  margin: 0;
}

.signupFooter {
  color: #6c7d71;
  font-size: 12px;
  font-weight: var(--fontWeightRegular);
  letter-spacing: normal;
  line-height: 19px;
  padding: 8px 15px 14px;
  text-align: center;

  & > span {
    display: block;
    margin: 0 auto;
    max-width: 337px;
  }
}

.termsLink {
  cursor: pointer;
  font-weight: var(--fontWeightSemiBold);

  &:hover {
    text-decoration: underline;
  }
}

.termsModal {
  @apply --marketplaceModalBaseStyles;
  min-height: 100vh;
  overflow: hidden;
  padding: 0;
  height: 100%;

  @media (--viewportMedium) {
    flex-basis: 720px;
    min-height: auto;
    height: auto;
  }

  & button span {
    display: none;
  }
}
